import Router from 'next/router'
import { LoginView } from '#hh/client/components'
import {
	chooseNavigatorLang,
	composePage,
	isClientSide,
	pushPageViewEvent,
} from '#hh/client/lib'
import {
	availableLocales,
	defaultLocale,
	chooseBrowserAcceptLang,
} from '#hh/shared/intl'
function LoginPage() {
	if (isClientSide()) {
		console.debug('[hh/client] login')
		pushPageViewEvent()
	}

	return <LoginView />
}

LoginPage.getInitialProps = async (ctx) => {
	// redirect to dashboard if user is logged in

	const session = ctx?.req?.session

	if (session?.user) {
		const locale =
			session.user.language ??
			chooseBrowserAcceptLang(
				ctx.req.headers['accept-language'] || '',
				availableLocales,
			) ??
			chooseNavigatorLang(availableLocales) ??
			defaultLocale

		const redirectTarget = `/${locale}/dashboard`
		if (ctx.res) {
			ctx.res.writeHead(307, { Location: redirectTarget })
			ctx.res.end()
		} else {
			Router.replace(redirectTarget)
		}
	}

	return {
		messages: ctx.messages,
		locale: ctx.locale,
	}
}

export default composePage({
	public: true,
})(LoginPage)
