import React, { forwardRef, useCallback, useState } from 'react'
import { useTextField } from 'react-aria'
import { useFormField } from '#hh/client/hooks'
import {
	Field,
	CommonFieldProps,
	TextInput,
	FieldLabel,
	FieldErrors,
	Icon,
	Button,
} from '#hh/client/components'
import clsx from 'clsx'

interface PasswordFieldProps
	extends CommonFieldProps<string, HTMLInputElement> {
	autoComplete?: string
	// autoComplete?: string
}

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
	(
		{
			name,
			label,
			description,
			defaultValue,
			onChange,
			required,
			...otherProps
		},
		ref,
	) => {
		const [displayPassword, setDisplayPassword] = useState<boolean>(false)
		const field = useFormField(name, {
			defaultValue,
		})
		const inputRef = React.useRef()
		const { labelProps, inputProps, descriptionProps, errorMessageProps } =
			useTextField(
				{
					label,
					type: displayPassword ? 'text' : 'password',
					value: field.value,
					isRequired: required,
					defaultValue,
					...otherProps,
				},
				inputRef,
			)

		const handleChange = useCallback(
			(newValue: string, evt: React.ChangeEvent<HTMLInputElement>) => {
				field.setValue(newValue, {
					shouldDirty: true,
					shouldValidate: true,
				})
				if (onChange) {
					onChange(newValue, evt)
				}
			},
			[name, onChange, field.setValue],
		)

		const hasError = !!field.errors
		const isDirty = field.dirty

		return (
			<div
				ref={ref}
				className={clsx('field field--text field--password', {
					'has:error': hasError,
					'is:dirty': isDirty,
				})}
			>
				<FieldLabel {...labelProps} field={field}>
					{label}
				</FieldLabel>
				{/* <div className="input-container"> */}
				<TextInput
					{...inputProps}
					onChange={handleChange}
					ref={inputRef}
					className="field__input"
				>
					{/* <Icon
						name={'visibility_' + (displayPassword ? 'off' : 'on')}
						onClick={() => setDisplayPassword(!displayPassword)}
						className="input__icon "
					/> */}
					<Button className="textInput__visibilityToggle" icon={'visibility_' + (displayPassword ? 'off' : 'on')} onPress={() => setDisplayPassword(!displayPassword)} />
				</TextInput>

				{/* </div> */}
				{Boolean(description) && <div {...descriptionProps}>{description}</div>}
				<FieldErrors {...errorMessageProps} field={field} />
			</div>
		)
	},
)

PasswordField.displayName = 'PasswordField'
