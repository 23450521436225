import { Link } from '#hh/client/components'
import NeptunLogo from '#hh/static/public/NeptunLogo.svg'
import { formatDate } from '#hh/shared/lib'
import { useTranslations } from '#hh/client/hooks'

export function PageFooter() {
	const { messages } = useTranslations()
	return (
		<footer className="pageFooter">
			<div className="pageFooter__content">
				<Link
					className="pageFooter__logo"
					href="https://headlinehunter.ai"
					target="_blank"
				>
					<NeptunLogo />
				</Link>
				<div className="pageFooter__copy">
					© {formatDate(new Date(), 'Y')} Neptun Data Processing GmbH
				</div>
				<ul className="pageFooter__links">
					<li className="pageFooter__link">
						<Link href="https://headlinehunter.ai/imprint">
							{messages.imprint}
						</Link>
					</li>
					<li className="pageFooter__link">
						<Link href="https://headlinehunter.ai/privacy">
							{messages.privacy}
						</Link>
					</li>
				</ul>
			</div>
		</footer>
	)
}
