import React from 'react'
import gql from 'graphql-tag'
import * as yup from 'yup'
import {
	Form,
	PasswordField,
	TextField,
	Button,
	View,
	Link,
	Spinner,
	PageFooter,
} from '#hh/client/components'
import { useForm, useTranslations, translateString } from '#hh/client/hooks'
import HHLogo from '#hh/static/public/headlinehunter-03.svg'
import TwitterLogo from '#hh/static/public/Twitter.svg'
import FacebookLogo from '#hh/static/public/Facebook.svg'
import InstagramLogo from '#hh/static/public/Instagram.svg'
import LinkedinLogo from '#hh/static/public/LinkedinLogo.svg'

export const LoginMutation = gql`
	mutation Login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			result {
				id
				email
			}
			errors {
				field
				message
			}
		}
	}
`

import ReactDOM from 'react-dom';

export function LoginView() {
	const { messages } = useTranslations()
	// console.debug('[hh/client] login view', messages)
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.email()
			.required(
				translateString('required_field', messages, { field: messages.login_email }),
			)
			.label(messages.login_email),
		password: yup
			.string()
			.required(
				translateString('required_field', messages, { field: messages.login_password }),
			)
			.label(messages.login_password),
	})
	const form = useForm({
		schema: validationSchema,
		mutation: LoginMutation,
		mutationErrorsPath: 'login.errors',
		mutationDataPath: 'login.result',
		onSubmissionResponse: async ({ data, errors }) => {
			if (errors) {
				console.error(errors)
			}

			if (data) {
				form.clearErrors()
				window.location.reload()
			}
		},
	})

	const isLoading = form.isLoading || form.isSubmitSuccessful

	return (
		<View title={messages.login_title} className="view--login view--page">
			<main className="viewMain">
				<div className="login floatyBox">
					<HHLogo className="login__logo" />
					<h1 className="login__title">{messages.login_title}</h1>
					<p className="login__description">{messages.login_description}</p>
					<Form {...form} className="login__form">
						<TextField
							name="email"
							label={messages.login_email}
							autoComplete="username"
						/>
						<PasswordField
							name="password"
							label={messages.login_password}
							autoComplete="current-password"
						/>
						<Button
							type="submit"
							className="button login__submit"
							icon={isLoading ? '' : 'lock_outline'}
						>
							{isLoading && (
								<Spinner inverted size="small" style={{ marginTop: '2px' }} />
							)}
							{messages.login_submit}
						</Button>
					</Form>
				</div>
				<div className="loginIntro floatyBox">
					<h1 className="floatyBox__title">{messages.login_cta_title}</h1>
					<p className="floatyBox__text">{messages.login_cta_subtitle}</p>
					<Link
						className="button floatyBox__action icon icon:arrow_forward"
						href="https://headlinehunter.ai/#call_to_action"
						target="_blank"
					>
						{messages.login_cta_button}
					</Link>
				</div>
				<div className="loginSocials">
					{/* <h2 className="loginSocials__title">Follow us</h2> */}
					<ul className="loginSocials__items">
						<li className="loginSocials__item">
							<Link
								href="https://www.linkedin.com/company/neptun-data-processing-gmbh"
								target="_blank"
								className="button button:naked has:iconOnly"
							>
								<LinkedinLogo className="loginSocials__logo" />
							</Link>
						</li>
						<li className="loginSocials__item">
							<Link
								href="https://www.twitter.com/neptun_ai"
								target="_blank"
								className="button button:naked has:iconOnly"
							>
								<TwitterLogo className="loginSocials__logo" />
							</Link>
						</li>
						<li className="loginSocials__item">
							<Link
								href="https://www.facebook.com/neptunai"
								target="_blank"
								className="button button:naked has:iconOnly"
							>
								<FacebookLogo className="loginSocials__logo" />
							</Link>
						</li>
						<li className="loginSocials__item">
							<Link
								href="https://www.instagram.com/headlinehunter_ai"
								target="_blank"
								className="button button:naked has:iconOnly"
							>
								<InstagramLogo className="loginSocials__logo" />
							</Link>
						</li>
					</ul>
				</div>
			</main>
			<PageFooter />
		</View>
	)
}
